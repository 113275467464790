import * as forms from "./core/forms";
import * as signatures from "./core/signatures";
import * as scroll from "./core/scroll";

window.addEventListener('load', function() {
  scroll.setup();

  // evag.io.
  forms.setup();
  signatures.setup();
});
