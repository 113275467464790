export const top = () => {
  const doc = document.documentElement;
  return (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
};

const scroll = function() {
  const body = document.getElementsByTagName('body')[0];

  return () => {
    if (top() > 300) {
      body.classList.add('on-scroll');
    } else {
      body.classList.remove('on-scroll');
    }
  };
};

export const setup = function() {
  const fn = scroll();
  window.addEventListener('scroll', fn);
  fn();
};
